import React from 'react';
import './assets/PickupDetails.css';
import validator from 'validator';
import TimePicker from '../time-picker/TimePicker';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";

const MapComponent = React.memo(({ loc, apiKey }) => {
  const MapWithScript = withScriptjs(withGoogleMap(() => (
    <GoogleMap
      defaultZoom={18}
      defaultCenter={{
        lat: loc?.coordinates?.latitude,
        lng: loc?.coordinates?.longitude
      }}
    >
      <Marker
        position={{
          lat: loc?.coordinates?.latitude,
          lng: loc?.coordinates?.longitude
        }}
      />
    </GoogleMap>
  )));

  return (
    <MapWithScript
      googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3.exp&libraries=geometry,drawing,places`}
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `200px` }} />}
      mapElement={<div style={{ height: `100%` }} />}
    />
  );
});
  

class PickupDetails extends React.Component {
    
    state = {
        pickup_details: {
          name: null,
          email: null,
          phone: null,
          notes: null,
          isFormComplete: false,
          scheduleType: (this.props.catalog === "regular") ? 'ASAP' : 'Schedule',
          pickupAt: new Date().toISOString(),
        }
      };
    
      handleChange(e, field) {
        const value = e.target.value;
        this.setState((prevState) => ({
          pickup_details: {
            ...prevState.pickup_details,
            [field]: value,
          },
          isFormComplete: this.isFormComplete({
            ...prevState.pickup_details,
            [field]: value,
          }),
        }));
      }

      isFormComplete(pickupDetails) {
        const { name, email, phone } = pickupDetails;
        return (
          name !== '' &&
          email !== '' &&
          phone !== '' &&
          validator.isAlpha(name || '', 'en-US', { ignore: 's' }) &&
          validator.isEmail(email || '') &&
          validator.isMobilePhone(phone || '')
        );
      }

      handleSubmit = () => {
        const { name, email, phone } = this.state.pickup_details;
        const isFormComplete = name !== null && email !== null && phone !== null;
        this.setState({ isFormComplete });
        this.props?.addPickupDetails(this.state);
      };

      handlePickupTimeChange = (time) => {
        const pickupHour = time.getHours();
        const pickupMinute = time.getMinutes();
      
        if (pickupHour > 11 || (pickupHour === 11 && pickupMinute >= 0)) {
          if (pickupHour < 20 || (pickupHour === 20 && pickupMinute === 0)) {
            this.setState((prevState) => ({
              pickup_details: {
                ...prevState.pickup_details,
                pickupAt: time.toISOString(),
              },
            }));
          } else {
            // Set the state to 8:00 PM if the time is after 8 PM
            const maxTime = new Date();
            maxTime.setHours(20);
            maxTime.setMinutes(0);
            maxTime.setSeconds(0);
            maxTime.setMilliseconds(0);
      
            this.setState((prevState) => ({
              pickup_details: {
                ...prevState.pickup_details,
                pickupAt: maxTime.toISOString(),
              },
            }));
          }
        } else {
          // Set the state to 11:00 AM if the time is before 11 AM
          console.log('why 11');
          const minTime = new Date();
          minTime.setHours(11);
          minTime.setMinutes(0);
          minTime.setSeconds(0);
          minTime.setMilliseconds(0);
      
          this.setState((prevState) => ({
            pickup_details: {
              ...prevState.pickup_details,
              pickupAt: minTime.toISOString(),
            },
          }));
        }
      };
      

      handleScheduleTypeChange = (scheduleType) => {
        console.log(scheduleType);
        this.setState((prevState) => ({
          pickup_details: {
            ...prevState.pickup_details,
            scheduleType,
          },
        }));
      };
      goToCheckout = () => {
        window.location.href = '/checkout/pickup';
      };
    
      render() {
        const loc = this.props?.locations?.find((l) => l.id === this.props?.location);
        const busyMode = this.props?.attributes?.busy_mode;
        let prepTime = busyMode ? this.props?.attributes?.preptime + 10 : this.props?.attributes?.preptime;
        //TEST
        if (!loc?.coordinates) {
          loc.coordinates = {
            latitude: 42.250555,
            longitude: -83.656782
          };
        }    
        const duration =
          this.props?.order?.fulfillments ? (
            <div className="col-6 text-end">
              {parseInt(this.props?.order?.fulfillments[0]?.pickupDetails?.prepTimeDuration.substr(2, 2)) - 5 || 25}-
              {parseInt(this.props?.order?.fulfillments[0]?.pickupDetails?.prepTimeDuration.substr(2, 2)) + 5 || 25}min.
            </div>
          ) : (
            <div className="col-6 text-end">
              {prepTime - 5} - {prepTime + 5} min.
            </div>
          );
    
        return (
          <div className="wrapper mb-2 pt-3">
            <MapComponent loc={loc} apiKey={process.env.REACT_APP_GOOGLE_API_KEY} />    
            <input
              className="w-100 my-2"
              type="text"
              value={this.props?.name}
              onChange={(e) => this.handleChange(e, 'name')}
              placeholder="Full name"
              required
            />
            {this.state?.pickup_details?.name !== null && !validator.isAlpha(this.state?.pickup_details?.name || '', 'en-US', { ignore: 's' }) && (
              <div className="error">Name not valid.</div>
            )}
    
            <input
              className="w-100 my-2"
              type="text"
              value={this.props?.email}
              onChange={(e) => this.handleChange(e, 'email')}
              placeholder="Email"
            />
            {this.state?.pickup_details?.email !== null && !validator.isEmail(this.state?.pickup_details?.email || '') && (
              <div className="error">Email is not valid.</div>
            )}
    
            <input
              className="w-100 my-2"
              type="text"
              value={this.props?.phone}
              onChange={(e) => this.handleChange(e, 'phone')}
              placeholder="Phone number"
            />
            {this.state?.pickup_details?.phone !== null &&
              !validator.isMobilePhone(this.state?.pickup_details?.phone || '') && (
                <div className="error">Phone number is not valid.</div>
              )}
    
            <hr />
    
            {this.props?.order?.fulfillments ? 
              <div className="row poppins text-color6">
                <div className="col-6">Pickup estimate</div>
                {duration}
              </div> :
              <TimePicker blockDelivery={this.props.catalog !== "regular" } onPickupTimeChange={this.handlePickupTimeChange} onScheduleTypeChange={this.handleScheduleTypeChange} scheduleType={this.state.pickup_details.scheduleType} pickupAt={this.state.pickup_details.pickupAt}/>
            }
            <br />
            <br />
    
            <button
            className="w-100 btn bg-color4 text-color6"
            onClick={this.handleSubmit}
            disabled={!this.state.isFormComplete}
            >
                Continue to payment
            </button>
          </div>
        );
      }
}

export default PickupDetails;