import React from "react";
import './assets/Locations.css';
import { Link } from 'react-router-dom';

const Locations = (props) => {
    const locations = props?.locations?.map(location =>
        <div class="col-6">
            <div class="dropdown">
                <button class="btn dropdown-toggle text-white poppins fw-bold bg-color1" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Order from {location?.address?.locality}
                </button>
                <ul class="dropdown-menu bg-color3">
                    <li>
                        <Link className="dropdown-item text-color1" to={"/menu"} onClick={(e) => props.setLocation(location.id)} key={location?.address?.locality}>
                            <span className='poppins fw-bold'>Main Menu</span>
                        </Link>
                        <Link className="dropdown-item text-color1" to={"/catering"} onClick={(e) => props.setLocation(location.id)} key={location?.address?.locality}>
                            <span className='poppins fw-bold'>Catering</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )

    return (
        <div className="col-12 col-md-6 center mb-5 pb-5 bg-color5-4 rounded rounded-5">
            <p className="text-white mt-3 poppins" style={{ fontSize: "28px" }}><strong>Place your order</strong></p>
            <div className="row">
                {locations}
            </div>
        </div>
    )
}

export default Locations;