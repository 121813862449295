import React from 'react';
import './assets/PaymentForm.css';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { isSafari } from "react-device-detect";
class PaymentForm extends React.Component {

    timerID;
    cashAppPay;
    googlePay;
    applePay;

    state = {
        appId: process.env.REACT_APP_APPLICATION_ID,
        locationId: this.props?.location,
        edit: false,
        tip: 0
    }

    componentDidUpdate(prevState){
        if(prevState.tip !== this.props.tip){
            this.setState({tip: this.props?.tip});
            this.tick();
        }
    }
    render() { 
        return (
          <div className="wrapper mb-2">
            {this.contactDetails()}
            <hr />
            { (this.props?.attributes?.pay_cashapp || this.props?.attributes?.pay_gpay || (isSafari && this.props?.attributes?.pay_apple_pay)) ?
            (<div className="row">
                <h3 className="poppins text-color6">Express Payment</h3>
                {(isSafari && this.props?.attributes?.pay_apple_pay) ? (
                <div className="col-md-4 p-2">
                  <form id="apple-pay-payment-form">
                    <div id="apple-pay-button"></div>
                  </form>
                  <div id="payment-status-container"></div>
                </div>) : <div></div>
                }
                {this.props?.attributes?.pay_gpay ? (
                <div className="col-md-4 col-md-offset-1 p-2">
                  <form id="google-payment-form">
                    <div id="google-pay-button"></div>
                  </form>
                  <div id="google-payment-status-container"></div>
                </div>) : <div></div>
                }
                {this.props?.attributes?.pay_cashapp ? (
                <div className="col-md-4 col-md-offset-1 p-2">
                  <form id="cash-app-payment-form">
                    <div id="cash-app-pay"></div>
                  </form>
                  <div id="cash-app-payment-status-container"></div>
                </div>) : <div></div>
                }
            </div>) : <div></div>
            }
            {/*isSafari ? (
              <div className="row">
                <h3 className="poppins text-color6">Express Payment</h3>
                <div className="col-md-4 p-2">
                  <form id="apple-pay-payment-form">
                    <div id="apple-pay-button"></div>
                  </form>
                  <div id="payment-status-container"></div>
                </div>
                <div className="col-md-4 col-md-offset-1 p-2">
                  <form id="google-payment-form">
                    <div id="google-pay-button"></div>
                  </form>
                  <div id="google-payment-status-container"></div>
                </div>
                <div className="col-md-4 col-md-offset-1 p-2">
                  <form id="cash-app-payment-form">
                    <div id="cash-app-pay"></div>
                  </form>
                  <div id="cash-app-payment-status-container"></div>
                </div>
              </div>
            ) : (
                <div className="row">
                <h3 className="poppins text-color6">Express Payment</h3>
                <div className="col-md-4 col-md-offset-1 p-2">
                  <form id="google-payment-form">
                    <div id="google-pay-button"></div>
                  </form>
                  <div id="google-payment-status-container"></div>
                </div>
                <div className="col-md-4 col-md-offset-1 p-2">
                  <form id="cash-app-payment-form">
                    <div id="cash-app-pay"></div>
                  </form>
                  <div id="cash-app-payment-status-container"></div>
                  
                </div>
              </div>
            )*/}
            <div className="row border rounded mt-5">
              <h3 className="poppins text-color6">Credit Card</h3>
              <small className="text-muted">
                All transactions are secure and encrypted by Square Inc
              </small>
              <div className="mt-2">
                <form id="payment-form mt-2">
                  <div id="card-container"></div>
                  <button
                    className="btn poppins bg-color4 w-100 text-color6"
                    id="card-button"
                    type="button"
                  >
                    Pay with card
                  </button>
                </form>
              </div>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                limit={2}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover={false}
                theme="colored"
              />
            </div>
            <hr/>
            <span className='poppins text-color6'>By placing an order with {process.env.REACT_APP_NAME}, customers agree to the <a href="/terms-and-conditions">Terms and Conditions</a></span>
          </div>
        );
    }

    contactDetails() {
        if (this.props?.cart?.fulfillments) {
            if(this.props?.cart?.fulfillments[0]?.pickupDetails){
                return (
                    <div>
                        <p className='poppins text-color6'>
                            <strong>Name: </strong>{this.props?.cart?.fulfillments[0]?.pickupDetails?.recipient?.displayName}<br />
                            <strong>Email: </strong>{this.props?.cart?.fulfillments[0]?.pickupDetails?.recipient?.emailAddress}<br />
                            <strong>Phone number: </strong>{this.props?.cart?.fulfillments[0]?.pickupDetails?.recipient?.phoneNumber}<br />
                            <hr />
                            <strong>Order Type: </strong>{this.props?.cart?.fulfillments[0]?.pickupDetails?.scheduleType}<br />
                            <strong>Pickup At: </strong>{new Date(this.props?.cart?.fulfillments[0]?.pickupDetails?.pickupAt).toLocaleString('en-US', {
                                dateStyle: 'medium',
                                timeStyle: 'short',
                                //timeZone: 'America/New_York', // Replace with the desired timezone
                                })}<br />
                        </p>
                        {/* <button className='btn bg-color6 poppins text-white' onClick={(e) => this.setState({ edit: true })}>Edit Pick up details</button> */}
                    </div>
                )
            }
            else {
                return (
                    <div>
                        <p className='poppins text-color6'>
                            <strong>Name: </strong>{this.props?.cart?.fulfillments[0]?.deliveryDetails?.recipient?.displayName}<br />
                            <strong>Email: </strong>{this.props?.cart?.fulfillments[0]?.deliveryDetails?.recipient?.emailAddress}<br />
                            <strong>Phone number: </strong>{this.props?.cart?.fulfillments[0]?.deliveryDetails?.recipient?.phoneNumber}<br />
                            <hr />
                            <strong>Delivery by: </strong>{this.props?.cart?.fulfillments[0]?.deliveryDetails?.courierProviderName}<br />
                            <strong>Dropoff Address: </strong>{this.props?.cart?.fulfillments[0]?.deliveryDetails?.recipient?.address?.addressLine1}, {this.props?.cart?.fulfillments[0]?.deliveryDetails?.recipient?.address?.administrativeDistrictLevel1}<br />
                            <strong>Dropoff Notes: </strong>{this.props?.cart?.fulfillments[0]?.deliveryDetails?.dropoffNotes}<br />
                            <strong>Estimated delivery: </strong>{new Date(this.props?.cart?.fulfillments[0]?.deliveryDetails?.deliverAt).toLocaleString('en-US', { dateStyle: 'medium', timeStyle: 'short' })}<br />                       

                        </p>
                        {/* <button className='btn bg-color6 poppins text-white' onClick={(e) => this.setState({ edit: true })}>Edit Pick up details</button> */}
                    </div>
                )
            }
        }
        else return (<br />)
    }

    displayPaymentResults(response, toastr) {
        if (response?.status && (response?.status === 'COMPLETED' || response?.status === 'APPROVED')) {
            toast.update(toastr, { render: "Payment succesful", type: "success", isLoading: false, autoClose: 1000 });
            localStorage.clear();
            window.location.href = `/order/${this.props?.cart?.id}`;

        } else {
            let error_message;
            if(response?.errors){
                if (response?.errors[0]?.code === "CVV_FAILURE") {
                    error_message = "Incorrect CVV";
                }
                else if (response?.errors[0]?.code === "ADDRESS_VERIFICATION_FAILURE") {
                    error_message = "Incorrect postal code";
                }
                else if (response?.errors[0]?.code === "INVALID_EXPIRATION") {
                    error_message = "Incorrect expiration date";
                }
                else if (response?.errors[0]?.code === "GENERIC_DECLINE") {
                    error_message = "Card declined";
                }
                else{
                    error_message = "Card declined";
                }
            }
            toast.update(toastr, { render: error_message, type: "error", isLoading: false, autoClose: 5000 });
        }
    }

    addPickupDetails = (state) => {
        this.props?.addPickupDetails(state);
        this.setState({ edit: false });
    }

    async componentDidMount() {
        if (this?.props?.cart?.tenders) {
            let shouldRedirect = false;
            for (const tender of this.props.cart.tenders) {
              if (tender.type === 'WALLET' || tender.cardDetails?.status === 'CAPTURED') {
                shouldRedirect = true;
                break;
              }
            }
            if (shouldRedirect) {
              localStorage.clear();
              window.location.href = `/order/${this.props?.cart?.id}`;
            }
          }
          

        if (!window.Square) {
            throw new Error('Square.js failed to load properly');
        }
        const payments = window.Square.payments(this.state.appId, this.state.locationId);

        let card;
        try {
            card = await this.initializeCard(payments);
        } catch (e) {
            console.error('Initializing Card failed', e);
            return;
        }

        const cardButton = document.getElementById('card-button');
        cardButton.addEventListener('click', async (event) => {
            event.preventDefault();
            cardButton.disabled = true;
            const toastr = toast.loading("Processing...",{autoClose: 5000});
            const token = await this.tokenize(card, toastr);
            try {
                const response = await axios.post(process.env.REACT_APP_API_URL + 'payment/create', {
                  orderId: this.props?.cart?.id,
                  locationId: this.props?.cart?.locationId,
                  token,
                  tipAmount: this.props?.tip
                });
                
                // console.log(response);
                if(response.data?.errors){
                    toast.update(toastr, { render: response.data.errors[0]?.detail, type: "error", isLoading: false, autoClose: 5000 });
                    if(response.data.errors[0]?.detail === 'The order is already paid.'){
                        setTimeout(() => {
                            window.location.href = `/order/${this.props?.cart?.id}`;
                        }, 2000);
                        cardButton.disabled = true;
                    } else {
                        cardButton.disabled = false;
                    }
                } else {
                    console.log(response?.data);
                    this.displayPaymentResults(response?.data, toastr);
                    cardButton.disabled = false;
                }
                // this.displayPaymentResults(response.data, toastr);
              } catch (error) {
                console.error(error);
                console.log(error.errors[0].detail)
                toast.update(toastr, { render: error.errors[0].detail, type: "error", isLoading: false, autoClose: 5000 });
              }
              
        });

        this.mountPaymentButtons(payments);
    }

    async initializeCard(payments) {
        const card = await payments.card();
        await card.attach('#card-container');
        return card;
    }

    async tokenize(paymentMethod, toastr) {
        const tokenResult = await paymentMethod.tokenize();
        if (tokenResult.status === 'OK') {
            return tokenResult.token;
        } else {
            toast.update(toastr, { render: "No card", type: "error", isLoading: false, autoClose: 5000 })
        }
    }


    /* CASH APP FUNCTIONS */
    buildPaymentRequest(payments) {
        const amount = (parseInt(this.props?.cart?.totalMoney.amount) + parseInt(this.props?.tip)) / 100;
        const paymentRequest = payments.paymentRequest({
            countryCode: 'US',
            currencyCode: 'USD',
            total: {
                amount: amount.toString(),
                label: 'Total',
            }
        });
        return paymentRequest;
    }

    async initializeCashApp(payments) {
        const paymentRequest = this.buildPaymentRequest(payments)
        const cashAppPay = await payments.cashAppPay(paymentRequest, {
            redirectURL: `${process.env.REACT_APP_WEBSITE}/order/${this.props?.cart?.id}`,
            referenceId: process.env.REACT_APP_NAME,
        });
        await cashAppPay.attach('#cash-app-pay');
        return cashAppPay;
    }

    /* GOOGLE FUNCTIONS */

    async initializeGooglePay(payments) {
        const paymentRequest = this.buildPaymentRequest(payments)
        const googlePay = await payments.googlePay(paymentRequest);
        await googlePay.attach('#google-pay-button', { buttonColor: 'black', buttonType: 'short' });
        return googlePay;
    }

    /* APPLE PAY FUNCTIONS */
    async initializeApplePay(payments) {
        const paymentRequest = this.buildPaymentRequest(payments)
        const applePay = await payments.applePay(paymentRequest);
        // Note: You do not need to `attach` applePay.
        return applePay;
    }
    tick() {
        if (!window.Square) {
            throw new Error('Square.js failed to load properly');
        }
        const payments = window.Square.payments(this.state.appId, this.state.locationId);
        try {
            this.googlePay.destroy();
            this.cashAppPay.destroy();
            if (isSafari) {
                this.applePay.destroy();
            }
        } catch (error) {
            console.log(error);
        }
        

        this.mountPaymentButtons(payments);
    }

    async mountPaymentButtons(payments){
        // INITIALIZE CASHAPP
        try {
            this.cashAppPay = await this.initializeCashApp(payments);
        } catch (e) {
            console.error('Initializing Cash App Pay failed', e);
        }

        this.cashAppPay.addEventListener('ontokenization', async (event) => {
            const { tokenResult, error } = event.detail;
            if (error) {
                // developer handles error
                console.log(error);
            }
            else if (tokenResult.status === 'OK') {
                const toastr = toast;
                // developer passes token to backend for use with CreatePayment
                await axios.post(
                    process.env.REACT_APP_API_URL + 'payment/create',
                    {
                        orderId: this.props?.cart?.id,
                        locationId: this.props?.cart?.locationId,
                        token: tokenResult.token,
                        tipAmount: this.props?.tip
                    }
                ).then(res => {
                    this.displayPaymentResults(res?.data, toastr);
                });
            }
        });

        // GOOGLE PAY
        try {
            if(this.props?.attributes?.pay_gpay){
                this.googlePay = await this.initializeGooglePay(payments);
            }
        } catch (e) {
            console.error('Initializing Google Pay failed', e);
        }
        if (this.googlePay !== undefined) {
            const googlePayButton = document.getElementById('google-pay-button');
            googlePayButton.addEventListener('click', async (event) => {
                event.preventDefault();
                const toastr = toast;
                const token = await this.tokenize(this.googlePay);
                await axios.post(
                    process.env.REACT_APP_API_URL + 'payment/create', {
                    orderId: this.props?.cart?.id,
                    locationId: this.props?.cart?.locationId,
                    token,
                    tipAmount: this.props?.tip
                }
                ).then(res => {
                    this.displayPaymentResults(res?.data, toastr);
                });
            });
        }

        // APPLE PAY
        if (isSafari) {
            try {
                this.applePay = await this.initializeApplePay(payments);
            } catch (e) {
                console.error('Initializing Apple Pay failed', e);
            }
            if (this.applePay !== undefined) {
                const applePayButton = document.getElementById('apple-pay-button');
                applePayButton.addEventListener('click', async (event) => {
                    event.preventDefault();
                    const toastr = toast;
                    const token = await this.tokenize(this.applePay);
                    await axios.post(
                        process.env.REACT_APP_API_URL + 'payment/create', {
                        orderId: this.props?.cart?.id,
                        locationId: this.props?.cart?.locationId,
                        token,
                        tipAmount: this.props?.tip
                    }
                    ).then(res => {
                        this.displayPaymentResults(res?.data, toastr);
                    });
                });
            }
        }
    }

}

export default PaymentForm;