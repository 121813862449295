import React from 'react';
import './assets/DeliveryDetails.css';
import validator from 'validator';
import { NumericFormat } from 'react-number-format';

class DeliveryDetails extends React.Component {
    state = {
        delivery_details: {
            address: null,
            name: null,
            email: null,
            phone: null,
            option: '1',
            notes: null,
        },
        autocomplete: null,
        orderId: null,
        location: null,
        deliveryQuote:null
    }

    handleChange(event, value) {
        const enteredValue = event.target.value;
        const updatedDeliveryDetails = {
          ...this.state.delivery_details,
          [value]: enteredValue
        };
      
        this.setState({
          delivery_details: updatedDeliveryDetails
        });
      
      }
      
      isAddressComplete(address) {
        const addressComponents = address.split(',').map(component => component.trim());
        return addressComponents.length === 4; // Assuming 4 required components
      }

    getQuote() {
        var place = this.state.autocomplete.getPlace();
        if(place){
            this.props?.getDeliveryQuote(place);
        }
    }

    addDeliveryDetails(e) {
        this.props?.addDeliveryDetails(this.state);
    }

    componentDidMount(){
        const autocomplete = new window.google.maps.places.Autocomplete(document.getElementById("address_input"), { types: ['geocode'] });
        this.setState({
            autocomplete: autocomplete,
            orderId: this.props.orderId,
            location: this.props.location
        });

        autocomplete.addListener("place_changed", () => {
            this.getQuote();
        })
    }
    componentDidUpdate(prevProps) {
        if (this.props.deliveryQuote && this.props.deliveryQuote !== prevProps.deliveryQuote) {
          this.setState({
            deliveryQuote: this.props.deliveryQuote
          });
        }
    }

    render() {
        return (
            <div className="wrapper mb-2">
                <input
                    className="w-100 my-2"
                    id="address_input"
                    type="text"
                    value={this.props?.address}
                    onChange={(e) => this.handleChange(e, 'address')} // Use onBlur event with handleChange function
                    onBlur={(e) => this.handleChange(e, 'address')} // Use onBlur event with handleChange function
                    placeholder="Address"
                    required
                />
                {this.state?.delivery_details?.address !== null && this.state?.delivery_details?.address === "" && (
                    <div className="error">Address not valid.</div>
                )}
                {this.props.deliveryQuote === "Allowed distance between addresses exceeded" && (
                     <div className="error">Allowed distance between addresses exceeded.</div>
                )}

                <input className="w-100 my-2" type="text" value={this.props?.name} onChange={(e) => this.handleChange(e, 'name')} placeholder="Full name" required />
                {this.state?.delivery_details?.name !== null && !validator.isAlpha(this.state?.delivery_details?.name || '', 'en-US', { ignore: 's' }) &&
                    <div className="error">Name not valid.</div>
                }
                <input className="w-100 my-2" type="text" value={this.props?.email} onChange={(e) => this.handleChange(e, 'email')} placeholder="Email" />
                {this.state?.delivery_details?.email !== null && !validator.isEmail(this.state?.delivery_details?.email || '') &&
                    <div className="error">Email is not valid.</div>
                }
                <input className="w-100 my-2" type="text" value={this.props?.phone} onChange={(e) => this.handleChange(e, 'phone')} placeholder="Phone number" />
                {this.state?.delivery_details?.phone !== null && !validator.isMobilePhone(this.state?.delivery_details?.phone || '') &&
                    <div className="error">Phone number is not valid.</div>
                }
                <hr />
                <div id='deliveryInformation'>
                    <label htmlFor="deliveryInformation" className='text-color6  fw-semibold ms-2 poppins'>
                        Delivery by DoorDash
                    </label>
                </div>
                {/* <div>
                    <input type="radio" id="delivery_option1" name="delivery_option" onChange={(e) => this.handleChange(e, 'option')} value="DOOR" checked={this.state?.delivery_details?.option === 'DOOR'} />
                    <label htmlFor="delivery_option1" className='text-color6 fw-semibold ms-2 poppins'>
                        Meet at the door
                    </label>
                </div>
                <div>
                    <input type="radio" id="delivery_option2" name="delivery_option" onChange={(e) => this.handleChange(e, 'option')} value="LEAVE_AT_DOOR" checked={this.state?.delivery_details?.option === 'LEAVE_AT_DOOR'} />
                    <label htmlFor="delivery_option2" className='text-color6 fw-semibold ms-2 poppins'>
                        Leave at the door
                    </label>
                </div>
                <div>
                    <input type="radio" id="delivery_option3" name="delivery_option" onChange={(e) => this.handleChange(e, 'option')} value="CURBSIDE" checked={this.state?.delivery_details?.option === 'CURBSIDE'} />
                    <label htmlFor="delivery_option3" className='text-color6 fw-semibold ms-2 poppins'>
                        Wait outside
                    </label>
                </div> */}
                <hr></hr>
                <div className='row poppins text-color6'>
                    <div className='col-6'>
                        Dropoff estimate
                    </div>
                    <div className='col-6 text-end'>
                    {this.props?.deliveryQuote && this.props?.deliveryQuote?.dropoff_time_estimated && this.state?.delivery_quote !== "Allowed distance between addresses exceeded" && (
                        new Date(this.props.deliveryQuote?.dropoff_time_estimated).toLocaleString('en-US', {
                        hour: 'numeric',
                        minute: 'numeric'
                        })
                    )}
                    </div>
                </div>
                <hr></hr>
                <div className='row poppins text-color6'>
                    <div className='col-6'>
                        Delivery fee
                    </div>
                    <div className='col-6 text-end'>
                        {this.props?.deliveryQuote && 
                            <NumericFormat value={this.props?.deliveryQuote?.fee / 100} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={2} />
                        }
                    </div>
                </div>
                <hr></hr>
                <div className='row poppins text-color6'>
                    <div className='col-12'>
                        DropOff Notes
                    </div>
                    <div className='col-12'>
                        <textarea rows="3" className='bg-color2 text-color6 w-100' placeholder="Type note" onChange={(e) => this.handleChange(e, 'notes')}/>
                    </div>
                </div>
                <br /><br />
                <button className='w-100 btn bg-color4 text-color6' onClick={(e) => this.addDeliveryDetails(e)} disabled={!(validator.isEmail(this.state?.delivery_details?.email || '') && validator.isMobilePhone(this.state?.delivery_details?.phone || '') && validator.isAlpha(this.state?.delivery_details?.name || '', 'en-US', { ignore: 's' }) && this.state?.delivery_details?.address !== "" && this.state?.delivery_details?.address !== null)}>Continue to payment</button>
            </div>
        );
    }
}

export default DeliveryDetails;