import Back from '../assets/minys/images/left.png';
import Logo from '../assets/minys/images/logo.png';
import CornerLogo from '../assets/minys/images/corner-logo.png';
import HomeImage from '../assets/minys/images/real-mexican-food.png';
import Edit from '../assets/minys/images/edit.png';
import Trash from '../assets/minys/images/trash.png';
import AddCart from '../assets/minys/images/add-cart.png';
import ReduceCart from '../assets/minys/images/reduce-cart.png';
import SvgFooter from '../assets/minys/images/svg-footer.svg';
import FbLogo from '../assets/minys/images/fb-logo.png';
import IgLogo from '../assets/minys/images/ig-logo.png';
import LocationIcon from '../assets/minys/images/location-icon.svg';
import CartIcon from '../assets/minys/images/cart-icon.svg';
import defaultLogo from '../assets/minys/images/MinysDefaultLogo.png';
import HomeButton from '../assets/minys/images/menu-button.png';
import X from '../assets/minys/images/x.png';

const resources = {
    logo: Logo,
    corner_logo: CornerLogo,
    home_image: HomeImage,
    Back,
    Edit,
    Trash,
    AddCart,
    ReduceCart,
    SvgFooter,
    FbLogo,
    IgLogo,
    LocationIcon,
    CartIcon,
    defaultLogo,
    HomeButton,
    X
}

export default resources;