import React from 'react';
import { Link } from 'react-router-dom';
import './assets/Home.css';
import Locations from './components/locations/Locations';
import data from '../assets/resources';
import ReactGA from "react-ga4";

class Home extends React.Component{
    componentDidMount() {
        document.title = `Home | ${process.env.REACT_APP_NAME}`;
        document.querySelector('meta[name="description"]').setAttribute("content", process.env.REACT_APP_DESCRIPTION);
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });
    }
    
    render() {
        var fragment;
        if(process.env.REACT_APP_NAME === "El Santo Kamaron"){
            fragment = (
                <React.Fragment>
                    <div className="row d-flex">
                        <div className="col-12 col-md-4">
                            <img className="img-fluid" src={data.home_image} alt="" />
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <Locations locations={this.props?.locations} setLocation={this.props?.setLocation} />
                        <div className="d-none d-md-block col-md-3"></div>
                    </div>
                </React.Fragment>
            )
        }
        else {
            fragment = (
                <React.Fragment>
                    <div className="row d-flex">
                        <div className="col-12 col-md-4 offset-md-4">
                            <img className="img-fluid" src={data.home_image} alt="" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="d-none d-md-block col-md-3"></div>
                        <Locations locations={this.props?.locations} setLocation={this.props?.setLocation} />
                        <div className="d-none d-md-block col-md-3"></div>
                    </div>
                </React.Fragment>
            )
        }
        return (
            <div className="background font-poppins">
                <div className="logo-container">
                    <Link to='/'><img className="logo-image" src={data.corner_logo} alt="" /></Link>
                </div>
                {fragment}
            </div>
        )
    }
}

export default Home;