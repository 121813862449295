import ReactGA from "react-ga4";
import React from 'react';
import {BrowserRouter, Route, Routes, useParams} from 'react-router-dom';

import "primereact/resources/themes/saga-blue/theme.css";     
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";  

import Home  from './Home/Home';
import Footer from './Footer/Footer';
import Contact from './Contact/Contact';
import Shop from './Shop/Shop';
import SelectCheckout from './SelectCheckout/SelectCheckout';
import Pay from './Pay/Pay';
import Order from './Order/Order';
import axios from 'axios';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import Open from './Open/Open';
// import GiftCards from "./GiftCards/GiftCards";


if(process.env.REACT_APP_NAME === "Miny's Mexican Restaurant")
  require('./assets/minys.css');
if(process.env.REACT_APP_NAME === "El Santo Kamaron")
  require('./assets/esk.css');

function OrderPage(params) {
  let { orderId } = useParams();
  return <Order catalog={params?.catalog} locations={params?.locations} location={params?.location} orderId={orderId}/>
}

class App extends React.Component {  
  constructor(props) {
    super(props);
    this.state = {
      locations: [],
      catalog: null,
      catering: null,
      location: null,
      locationsStatus: null,
      attributes: null
    };
    this.setLocation = this.setLocation.bind(this);
  }

  componentDidUpdate(prevState,prevProps) {
    if(prevProps.location !== this.state.location){
      this.setState({catalog:null});
      this.getCatalogFromLocation(this.state.location);
      this.getCateringFromLocation(this.state.location);
    }
  }
  componentDidMount(){
    //Get token from backend
    ReactGA.initialize(process.env.REACT_APP_GA);
  }

  getLocations(){
    axios.get(process.env.REACT_APP_API_URL+'location',)
        .then(res => {
          this.setState({
            locations: res?.data?.locations,
          });
        })
        .catch(err => console.log(err));
  }

  getStoreStatus(){
    axios.post(process.env.REACT_APP_API_URL+'location/list-locations', {
      'location': process.env.REACT_APP_NAME
    }).then(res => {
      this.setState({locationsStatus: res.data});
    }).catch(err => console.log(err));
  }

  getCatalogFromLocation = (location) => {
    axios.get(process.env.REACT_APP_API_URL+`catalog?location=${location}`)
        .then(res => {this.setState({catalog: res?.data})
      })
        .catch(err => console.log(err));
  }

  getCateringFromLocation = (location) => {
    axios.get(process.env.REACT_APP_API_URL+`catalog/catering?location=${location}`)
        .then(res => {this.setState({catering: res?.data})
      })
        .catch(err => console.log(err));
  }
 
  setLocation = (location) => {
    axios.post(process.env.REACT_APP_API_URL+'location/check', {
      'location': location
    }).then(res => {
      if(res?.data?.message === 'This store is closed'){
        this.setState({open: false, location,attributes:res.data.attributes})
      } else{
        this.setState({open: res.data?.attributes?.open, location,attributes:res.data.attributes})
      }
    }).catch(err => console.log(err));
  }

  render() {
    if(!this.state.locations?.length > 0) {
      this.getLocations();
    }
    const { catalog,catering, locations, location, open, locationsStatus, attributes } = this.state;
    return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home locations={locations} setLocation={this.setLocation} attributes={ attributes } />}/>
            <Route path='/menu' element={<Shop open={open} catalog={catalog} locations={locations} location={location} setLocation={this.setLocation} attributes={ attributes } />}/>
            <Route path='/catering' element={<Shop open={open} catalog={catering} locations={locations} location={location} setLocation={this.setLocation} attributes={ attributes } />}/>
            <Route path='/checkout' element={ <SelectCheckout open={open} catalog={catalog} catalog_catering={catering} locations={locations} location={location} setLocation={this.setLocation} attributes={ attributes } /> }/>
            <Route path='/pay' element={ <Pay open={open} catalog={catalog} locations={locations} location={location} setLocation={this.setLocation} attributes={ attributes } /> }/>
            <Route path='/contact' element={<Contact locations={locations} attributes={ attributes }/>}/>
            <Route path='/order/:orderId' element={<OrderPage locations={locations} location={location} attributes={ attributes } catalog={catalog}/>}/>
            <Route path='/terms-and-conditions' element={<TermsAndConditions/>}/>
            <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
            <Route path='/open' element={<Open open={open} locations={locations} openOrClose={this.openOrClose} locationsStatus={locationsStatus}/>}/>
            {/* <Route path='/gift-cards' element={<GiftCards/>}/> */}
          </Routes>
        </BrowserRouter>
        <Footer locations={locations} location={location}/>
      </div>
    );
  }
}

export default App;