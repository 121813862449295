import React from 'react';
import axios from 'axios';
import data from '../assets/resources';
import './assets/SelectCheckout.css';
import CartDescription from '../Shared/cart-description/CartDescription';
import PickupDetails from './components/pickup-details/PickupDetails';
import DeliveryDetails from './components/delivery-details/DeliveryDetails';
import { Link } from 'react-router-dom';
import ReactGA from "react-ga4";
import LoadingScreen from '../LoadingScreen/LoadingScreen';
import loadingImage from './../assets/minys/images/loading.gif'

class SelectCheckout extends React.Component {
    state = {
        cart: { lineItems: null },
        tip: 0,
        pickupOrDelivery: 'pickup',
        delivery_quote: null,
        catalog: localStorage.getItem("orderType")
    };

    componentDidMount() {
        document.title = `Checkout | ${process.env.REACT_APP_NAME}`;
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });
        const currentLocation = localStorage.getItem('locationId');
        const orderId = localStorage.getItem('orderId');
        if (currentLocation && orderId) {
            this.props.setLocation(currentLocation);
            this.getCart(orderId);
        } else {
            window.location.href = "/menu";
        }

    }
    render() {

        if (!this.props?.catalog?.length) {
            return (
                <LoadingScreen imageSource={loadingImage} />
            )            
        }
        return (
            <div className='shop bg-color2'>
                <div className='corner-logo position-absolute d-none d-md-block'>
                    <a href="/">
                        <img className='img-fluid' src={data.corner_logo} alt="" />
                    </a>
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className='text-center' style={{ paddingTop: "100px" }}>
                            <a href="/menu" className='poppins text-color6 fst-italic'>
                                <img src={data.Back} alt={'back to home'} />
                                <small>Back to menu</small>
                            </a>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className='text-center' style={{ paddingTop: "100px" }}>
                            <Link to="/" className='poppins text-color6 fst-italic'>
                                <img src={data.Back} alt={'Back to home'} />
                                <small>Back to home</small>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="pt-5">
                                <div>
                                    <h3 className='poppins text-color6'>Get it</h3>
                                    <div className='container'>
                                        <div className="row">
                                            <div className="col-8 offset-2 border-color6 p-2 ps-4">
                                                <div className="row">
                                                    {/* Check if the delivery is active, from the CMS */}
                                                    {(this.props.attributes?.delivery && this.state.catalog === "regular") && (
                                                        <div style={{ 'cursor': 'pointer' }} onClick={(e) => this.setState({ pickupOrDelivery: "delivery" })} className={this.state.pickupOrDelivery === "delivery" ? 'bg-color6 poppins text-color3 col-6' : 'poppins text-color6 col-6'}>
                                                            Delivery
                                                        </div>
                                                    )}
                                                    {/* Check if the pickup is active, from the CMS */}
                                                    {this.props.attributes?.pickup && (
                                                        <div style={{ 'cursor': 'pointer' }} onClick={(e) => this.setState({ pickupOrDelivery: "pickup" })} className={this.state.pickupOrDelivery === "pickup" ? 'bg-color6 poppins text-color3 col-6' : 'poppins text-color6 col-6'}>
                                                            Pickup
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.pickupOrDelivery()}
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-8 pt-5"><h3 className='poppins text-color6 mt-5'>Your order</h3>
                            <div className="items-description mb-5">
                                <CartDescription edit={true} updateItem={this.updateItem} items={this.state?.cart} setQuantity={this.setQuantity} catalog={this.state.catalog === "regular" ? this.props?.catalog : this.props?.catalog_catering} tip={this.state?.tip} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    addPickupDetails = (state) => {
        const currentLocation = localStorage.getItem('locationId');
        const orderId = localStorage.getItem('orderId');
        const body = {
            "locationId": currentLocation,
            "orderId": orderId,
            "pickupName": state?.pickup_details?.name,
            "pickupEmail": state?.pickup_details?.email,
            "pickupNumber": state?.pickup_details?.phone,
            "scheduleType": state?.pickup_details?.scheduleType,
            "pickupAt": state?.pickup_details?.pickupAt
        }
        axios.patch(process.env.REACT_APP_API_URL + 'orders/add-pickup-details', body).then(res => {
            window.location.href = '/pay';
        });
    }

    addDeliveryDetails = (state) => {
        const body = {
            delivery_details: state?.delivery_details,
            location: state.location,
            orderId: state.orderId,
            deliveryQuote: state?.deliveryQuote
        };

        axios.post(process.env.REACT_APP_API_URL + 'orders/add-delivery-details', body).then(res => {
            window.location.href = '/pay';
        });
    }

    getDeliveryQuote = (place) => {
        const currentLocation = this.props.locations.find(l => l.id === localStorage.getItem('locationId'));
        const body = {
            pickup_address: currentLocation?.address?.address_line_1 + ", " + currentLocation?.address?.locality + ", " + currentLocation?.address?.administrative_district_level_1 + ", " + currentLocation?.address?.country,
            dropoff_address: place?.formatted_address,
            orderId: this.state?.cart?.id,
            businessName: currentLocation.business_name
        }
        // axios.post(process.env.REACT_APP_API_URL + 'delivery/quote', body).then(res => {
        //     this.setState({ delivery_quote: res.data })
        // });
        axios.post(process.env.REACT_APP_API_URL + 'delivery/quote-doordash', body)
            .then(res => {
                if (res.data && res.data.errorCode === 'invalid_delivery_parameters' && res.data.message === 'Allowed distance between addresses exceeded') {
                // Handle the error case
                this.setState({ delivery_quote: 'Allowed distance between addresses exceeded' });
                } else {
                // Proceed with normal flow
                this.setState({ delivery_quote: res.data.data });
                }
            });
    }

    getCart(orderId) {
        axios.get(process.env.REACT_APP_API_URL + 'orders/?orderId=' + orderId).then(res => {
            if (!res?.data?.lineItems || res?.data?.lineItems.length === 0) {
                window.location.href = "/menu";
            }
            this.setState({ cart: res?.data })
        });
    }

    pickupOrDelivery() {
        if (this.state?.pickupOrDelivery === "delivery")
            return (<DeliveryDetails addDeliveryDetails={this.addDeliveryDetails} getDeliveryQuote={this.getDeliveryQuote} deliveryQuote={this.state.delivery_quote} location={this.props.location} orderId={this.state?.cart?.id} />);
        else if (this.state?.pickupOrDelivery === "pickup")
            return (<PickupDetails addPickupDetails={this.addPickupDetails} location={this.props.location} locations={this.props.locations} order={this.state.cart} attributes={this.props.attributes} catalog={this.state.catalog}/>);
    }

    setQuantity = (uid, quantity) => {
        this.state?.cart?.lineItems?.forEach(item => {
            if (item.uid === uid) {
                item.quantity = quantity;
                if (quantity !== 0)
                    axios.patch(
                        process.env.REACT_APP_API_URL + 'orders/update', {
                        "orderId": this.state?.cart?.id,
                        "locationId": this.state?.cart?.locationId,
                        "lineItems": this.state?.cart?.lineItems,
                        "calculationPhase": "TOTAL_PHASE",
                        "state": "OPEN"
                    })
                        .then(res => {
                            localStorage.setItem('orderId', res?.data?.order?.id);
                            this.setState({ cart: res?.data?.order });
                        });
                else {
                    axios.patch(
                        process.env.REACT_APP_API_URL + 'orders/update', {
                        "orderId": this.state?.cart?.id,
                        "deleteItem": [uid],
                        "locationId": this.state?.cart?.locationId,
                        "calculationPhase": "TOTAL_PHASE",
                        "state": "OPEN"
                    })
                        .then(res => {
                            localStorage.setItem('orderId', res?.data?.order?.id);
                            this.setState({ cart: res?.data?.order });
                        });
                }
            }
        });
    }

    updateItem = (product_to_update) => {
        var updatedLineItems = []
        this.state?.cart?.lineItems?.forEach(item => {
            if (item.uid === product_to_update?.uid) {
                updatedLineItems.push({
                    ...item,
                    modifiers: product_to_update.modifiers_to_add.concat(item?.modifiers),
                    quantity: product_to_update.quantity,
                    catalogObjectId: product_to_update.variation,
                });
            }
            else{
                updatedLineItems.push(item)
            }
        });

        axios.patch(process.env.REACT_APP_API_URL + 'orders/update', {
            "orderId": this.state?.cart?.id,
            "locationId": this.props.location,
            "lineItems": updatedLineItems,
            "calculationPhase": "TOTAL_PHASE",
            "state": "OPEN"
        }).then(res => {
            axios.patch(process.env.REACT_APP_API_URL + 'orders/update', {
                "orderId": res?.data.order?.id,
                "locationId": this.props.location,
                "deleteItem": product_to_update.modifiers_to_delete,
                "calculationPhase": "TOTAL_PHASE",
                "state": "OPEN"
            }).then(res => {
                this.setState({ cart: res?.data.order })
            }).catch(err => console.log(err))
        }).catch(err => console.log(err))

        /*const updatedLineItems = [];

        this.state?.cart?.lineItems?.forEach(item => {
            if (item.uid === product_to_update?.uid) {
                updatedLineItems.push({
                    ...item,
                    modifiers: product_to_update.modifiers_to_add,
                    quantity: product_to_update.quantity,
                    catalogObjectId: product_to_update.variation,
                });
            }
        });
        

        const updatedLineItemsWithDeletedFields = updatedLineItems.reduce((acc, item) => {
            return {
                ...acc,
                [item.uid]: {
                    ...item,
                    grossSalesMoney: undefined,
                    totalTaxMoney: undefined,
                    totalMoney: undefined,
                    totalDiscountMoney: undefined,
                    appliedTaxes: undefined
                }
            }
        }, {});
        updatedLineItems.forEach(item => {
            item = {
                ...item,
                grossSalesMoney: undefined,
                totalTaxMoney: undefined,
                totalMoney: undefined,
                totalDiscountMoney: undefined,
                appliedTaxes: undefined
            }
        });
        const updatedLineItemsWithDeletedFields = updatedLineItems;

        updatedLineItemsWithDeletedFields.forEach( item => {
            if(item?.modifiers){
                var i = updatedLineItems?.find(i => i.catalogObjectId === item.catalogObjectId);
                var temp = []
                item?.modifiers?.forEach( modifier => {
                    if(i.modifiers){
                        var mod = i.modifiers?.find(m => m.catalogObjectId === modifier.catalogObjectId);
                        if(!mod){
                            temp.push(modifier)
                        }
                    }
                });
                item.modifiers = temp;
            }
        });

        console.log("Update 1", {
            "orderId": this.state?.cart?.id,
            "locationId": this.props.location,
            "deleteItem": product_to_update.modifiers_to_delete,
            "lineItems": updatedLineItemsWithDeletedFields,
            "calculationPhase": "TOTAL_PHASE",
            "state": "OPEN"
        });
        axios.patch(process.env.REACT_APP_API_URL + 'orders/update', {
            "orderId": this.state?.cart?.id,
            "locationId": this.props.location,
            "deleteItem": product_to_update.modifiers_to_delete,
            "lineItems": updatedLineItemsWithDeletedFields,
            "calculationPhase": "TOTAL_PHASE",
            "state": "OPEN"
        }).then(res => {
            console.log("Res", res);
            /*updatedLineItemsWithDeletedFields.forEach( item => {
                if(item?.modifiers){
                    var i = res?.data?.order?.lineItems?.find(i => i.catalogObjectId === item.catalogObjectId);
                    var temp = []
                    item?.modifiers?.forEach( modifier => {
                        if(i.modifiers){
                            var mod = i.modifiers?.find(m => m.catalogObjectId === modifier.catalogObjectId);
                            if(!mod){
                                temp.push(modifier)
                            }
                        }
                    });
                    item.modifiers = temp;
                }
            });

            console.log("updatedLineItemsWithDeletedFields", updatedLineItemsWithDeletedFields);

            if (this.checkModifiersNotZero(updatedLineItemsWithDeletedFields)){
                console.log("Update 2", {
                    "orderId": this.state?.cart?.id,
                    "locationId": this.props.location,
                    "lineItems": updatedLineItemsWithDeletedFields,
                    "calculationPhase": "TOTAL_PHASE",
                    "state": "OPEN"
                });
                axios.patch(process.env.REACT_APP_API_URL + 'orders/update', {
                    "orderId": this.state?.cart?.id,
                    "locationId": this.props.location,
                    "lineItems": updatedLineItemsWithDeletedFields,
                    "calculationPhase": "TOTAL_PHASE",
                    "state": "OPEN"
                }).then(res => {
                    console.log("Res 2", res);
                    this.setState({ cart: res?.data.order })
                }).catch(err => console.log(err))
            }
            else {
                this.setState({ cart: res?.data.order })
            }
        }).catch(err => console.log(err));*/
    }

    checkModifiersNotZero(updatedLineItemsWithDeletedFields){
        var valid = true;
        updatedLineItemsWithDeletedFields.forEach(item => {
            if (item.modifiers.length === 0) valid = false; 
        })
        return valid;
    }

}

export default SelectCheckout;
